import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Link } from '@components/Link'
import { Logo, Account, Cart, Hamburger, CaretDown } from '@components/Icon'
import { MainMenu } from './MainMenu'
import { MenuItem } from './MenuItem'

const ShopMenu = () => (
  <Flex
    direction="row"
    alignItems="center"
    justifyContent="flex-end"
    pr={8}
    py={4}
    bgColor="white"
    pos="relative"
    zIndex={110}
  >
    <Link
      to="https://shop.reesesbookclub.com/account"
      w={6}
      mr={4}
      display="inline-block"
    >
      <Account />
    </Link>
    <Link
      to="https://shop.reesesbookclub.com/cart"
      w={5}
      display="inline-block"
    >
      <Cart />
    </Link>
  </Flex>
)

export const Navigation = () => {
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [isShopExpanded, setShopExpanded] = useState(false)
  const bp = useBreakpoint()
  const isBase = bp === 'sm' || bp === 'base'

  return (
    <Box as="nav" bgColor="white" py={isBase ? 4 : 0}>
      <Box pos="relative">
        <Grid templateColumns="1fr auto 1fr" pos="relative">
          {isBase && (
            <Flex
              w={6}
              direction="column"
              justifyContent="center"
              alignItems="center"
              ml={8}
              cursor="pointer"
              onClick={onOpen}
            >
              <Hamburger />
            </Flex>
          )}
          <Box
            pl={isBase ? 0 : 8}
            py={4}
            bgColor="white"
            pos="relative"
            zIndex={110}
          >
            <Box maxW="4.5rem">
              <Link
                to="/"
                _focus={{
                  outline: 'none',
                }}
              >
                <Logo />
              </Link>
            </Box>
          </Box>
          {!isBase && <MainMenu />}
        </Grid>
      </Box>
      {isBase && (
        <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerBody>
              <Box maxW="6rem" ml={4} mt={4}>
                <Logo />
              </Box>
              <Box py={12}>
                <Box mb={4}>
                  <MenuItem
                    href="/picks"
                    title="Our Picks"
                    size="xl"
                    key="mobile-our-picks"
                  />
                </Box>
                <Box mb={4}>
                  <MenuItem
                    href="/"
                    title="Who We Are"
                    size="xl"
                    key="mobile-who-we-are"
                  />
                </Box>
                <Box mb={4}>
                  <MenuItem
                    href="/readership"
                    title="Readership"
                    size="xl"
                    key="mobile-readership"
                  />
                </Box>
                <Box mb={4}>
                  <MenuItem
                    href="/litup"
                    title="Lit Up"
                    size="xl"
                    key="lit-up"
                  />
                </Box>
                <Box mb={4}>
                  <MenuItem
                    href="https://shop.reesesbookclub.com/"
                    title="Shop"
                    size="xl"
                    key="mobile-shop"
                  />
                </Box>
                <Box mb={4}>
                  <MenuItem href="/faq" title="FAQs" size="xl" key="faqs" />
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  )
}
