export const RightArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8L15 8"
      stroke="#001A5A"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M11 13L15 8L11 3"
      stroke="#001A5A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
