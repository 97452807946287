export const Logo = () => {
  return (
    <svg viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.23938 20.9241L11.1888 18.1683C12.2589 16.6554 13.7142 16.5015 14.9679 17.388C15.9624 18.0918 16.3665 19.1241 15.9975 20.241L20.8629 20.538L19.7541 22.1049L15.0534 21.8268L14.5332 22.5621L17.7966 24.8715L16.803 26.2764L9.23938 20.9241ZM14.2011 20.2842C14.5602 19.7766 14.5908 19.2294 13.9743 18.7938C13.3587 18.3582 12.8529 18.5679 12.4938 19.0755L11.5299 20.4372L13.2372 21.645L14.2011 20.2842Z"
        fill="black"
      />
      <path
        d="M36.0612 12.0277L37.5534 11.3428C37.6812 12.4012 38.1222 13.3138 39.1329 13.447C39.8151 13.537 40.3686 13.2886 40.4703 12.5146C40.5774 11.701 39.5919 11.1709 38.5866 10.4914C37.7523 9.92795 37.0062 9.10895 37.1853 7.74365C37.368 6.35225 38.5461 5.41265 40.0689 5.61245C41.3685 5.78345 42.1416 6.49895 42.4485 7.62125L40.9545 8.31965C40.7997 7.56455 40.4001 7.25855 39.8619 7.18835C39.3633 7.12265 38.8836 7.42055 38.8197 7.90565C38.7198 8.66705 39.5622 9.16475 40.4505 9.72185C41.4612 10.3618 42.3909 11.1115 42.1875 12.6604C41.967 14.3407 40.6062 15.2434 38.8872 15.0175C37.2195 14.7979 36.1656 13.4704 36.0612 12.0277Z"
        fill="black"
      />
      <path
        d="M53.6022 15.1516C54.2331 15.2317 54.7983 15.0706 55.2906 14.7151C55.0269 14.1625 55.1502 13.699 55.4211 13.4083C55.8 13.0015 56.4624 12.9304 56.9466 13.3804C57.4317 13.8313 57.4182 14.5054 56.9772 14.9806C56.1393 15.8824 54.8829 15.8527 53.5536 15.6115L53.6022 15.1516Z"
        fill="black"
      />
      <path
        d="M54.1593 20.3491L55.6542 21.0286C54.9378 21.8179 54.5364 22.7485 55.098 23.5999C55.4769 24.175 56.0259 24.4306 56.6784 24.0004C57.3633 23.5486 57.1194 22.4569 56.9754 21.2518C56.8557 20.2528 56.9862 19.1512 58.1355 18.3934C59.3073 17.6212 60.7887 17.8966 61.6338 19.1782C62.3556 20.2726 62.3205 21.3265 61.6734 22.2922L60.1677 21.6199C60.6375 21.0088 60.6069 20.5057 60.3081 20.053C60.0309 19.6327 59.4927 19.4653 59.0832 19.7344C58.4424 20.1574 58.617 21.1204 58.7772 22.1563C58.9545 23.3398 58.9968 24.5332 57.6927 25.3927C56.2779 26.3251 54.7047 25.8877 53.7507 24.4405C52.8246 23.0374 53.1369 21.3724 54.1593 20.3491Z"
        fill="black"
      />
      <path
        d="M16.9812 11.936L21.7764 8.7959L22.6467 10.1252L19.2906 12.3221L20.4363 14.0717L23.1273 12.3095L23.9976 13.6388L21.3066 15.401L22.626 17.417L26.0928 15.1472L26.9631 16.4765L22.0572 19.6886L16.9812 11.936Z"
        fill="black"
      />
      <path
        d="M26.7642 6.74469L32.3631 5.51709L32.7033 7.06869L28.7856 7.92819L29.2338 9.97119L32.3757 9.28179L32.7159 10.8334L29.574 11.5228L30.0897 13.8763L34.137 12.9889L34.4772 14.5405L28.7487 15.7969L26.7642 6.74469Z"
        fill="black"
      />
      <path
        d="M47.8107 7.68604L52.8813 10.3599L52.1406 11.7648L48.5928 9.89373L47.6172 11.7441L50.463 13.2444L49.7223 14.6493L46.8765 13.149L45.7524 15.2802L49.4172 17.2125L48.6765 18.6174L43.4889 15.8823L47.8107 7.68604Z"
        fill="black"
      />
      <path
        d="M14.5179 34.5808L14.7681 37.9603C14.8842 39.5317 14.1444 40.6612 12.4686 40.7854C11.7162 40.8412 11.0448 40.5586 10.6983 40.0006C10.4067 40.8979 9.71819 41.4667 8.54279 41.5531C6.74729 41.6854 5.67179 40.5973 5.53229 38.7091L5.27759 35.2639L14.5179 34.5808ZM7.10369 38.4337C7.18289 39.5029 7.66259 39.8926 8.45459 39.8341C9.24659 39.7756 9.66419 39.3202 9.58499 38.251L9.46889 36.6796L6.98669 36.8632L7.10369 38.4337ZM11.1699 38.1331C11.2203 38.8198 11.5011 39.1969 12.2535 39.1411C13.0059 39.0853 13.2273 38.6713 13.1742 37.945L13.0608 36.4141L11.0538 36.5626L11.1699 38.1331Z"
        fill="black"
      />
      <path
        d="M10.7928 43.5952C13.6746 42.1669 15.9381 43.3054 16.9785 45.4042C18.0189 47.503 17.5545 49.9942 14.6727 51.4225C11.7909 52.8508 9.5274 51.7123 8.487 49.6135C7.4466 47.5147 7.9101 45.0235 10.7928 43.5952ZM13.8969 49.8574C15.8652 48.8818 16.173 47.3554 15.5556 46.1098C14.9382 44.8642 13.5378 44.1847 11.5686 45.1612C9.6003 46.1368 9.2925 47.6632 9.9099 48.9088C10.5273 50.1535 11.9277 50.833 13.8969 49.8574Z"
        fill="black"
      />
      <path
        d="M16.1334 53.2558C18.1791 50.7736 20.7108 50.8879 22.518 52.3783C24.3252 53.8687 24.9201 56.3311 22.8735 58.8133C20.8269 61.2946 18.2961 61.1812 16.4889 59.6908C14.6817 58.2004 14.0868 55.738 16.1334 53.2558ZM21.5262 57.7018C22.9239 56.0062 22.5801 54.4879 21.5082 53.6041C20.4354 52.7203 18.8793 52.6726 17.4816 54.3673C16.0839 56.0629 16.4277 57.5812 17.4996 58.465C18.5715 59.3497 20.1276 59.3974 21.5262 57.7018Z"
        fill="black"
      />
      <path
        d="M26.9766 55.584L28.629 56.0655L27.666 59.3703L31.6665 56.9511L33.6996 57.5433L28.9314 60.4701L31.3623 66.5145L29.2653 65.9034L27.2736 60.7176L26.037 64.9629L24.3846 64.4814L26.9766 55.584Z"
        fill="black"
      />
      <path
        d="M42.894 61.3603C41.625 58.5343 42.8364 56.1907 44.9496 55.2421C46.7856 54.4177 48.4758 54.7903 49.6017 56.2294L48.3516 57.2266C47.6406 56.5156 46.7721 56.1655 45.6012 56.6911C44.2971 57.277 43.6104 58.6882 44.4888 60.6439C45.3672 62.6005 46.8783 63.0244 48.1824 62.4385C49.3542 61.9129 49.6692 61.0309 49.6098 60.0274L51.1857 59.7556C51.5142 61.5529 50.6691 63.064 48.8331 63.8884C46.7199 64.8379 44.163 64.1863 42.894 61.3603Z"
        fill="black"
      />
      <path
        d="M49.1211 52.9903L50.3496 51.7861L55.7244 57.2689L58.5324 54.5167L59.6439 55.6507L55.6074 59.6071L49.1211 52.9903Z"
        fill="black"
      />
      <path
        d="M58.5558 51.355L53.4375 48.745L54.2196 47.2123L59.3613 49.8349C60.5637 50.4487 61.4124 50.2417 61.9416 49.204C62.4708 48.1663 62.1396 47.3581 60.9372 46.7452L55.7955 44.1225L56.5776 42.5898L61.6959 45.1999C63.9009 46.3249 64.278 48.1213 63.3573 49.9258C62.4366 51.7294 60.7608 52.48 58.5558 51.355Z"
        fill="black"
      />
      <path
        d="M57.0024 40.7773L57.276 37.3996C57.4029 35.83 58.3083 34.8274 59.9832 34.9633C60.7356 35.0245 61.3548 35.4061 61.6113 36.0118C62.0379 35.1703 62.8056 34.714 63.9801 34.8094C65.7747 34.9552 66.6702 36.1963 66.5172 38.0827L66.2382 41.5261L57.0024 40.7773ZM60.8571 37.783C60.9129 37.0972 60.6942 36.6805 59.9418 36.6202C59.1894 36.559 58.9068 36.9343 58.8483 37.6606L58.7241 39.1915L60.7293 39.3544L60.8571 37.783ZM64.9215 38.1124C65.0079 37.0441 64.5939 36.5851 63.8019 36.5212C63.0099 36.4573 62.5275 36.8434 62.4411 37.9117L62.3142 39.4822L64.7946 39.6829L64.9215 38.1124Z"
        fill="black"
      />
      <path
        d="M36 0C16.1181 0 0 16.1181 0 36C0 55.8819 16.1181 72 36 72C55.8819 72 72 55.8819 72 36C72 16.1181 55.8819 0 36 0ZM36 70.4115C16.9947 70.4115 1.5885 55.0053 1.5885 36C1.5885 16.9947 16.9947 1.5885 36 1.5885C55.0053 1.5885 70.4115 16.9947 70.4115 36C70.4115 55.0053 55.0053 70.4115 36 70.4115Z"
        fill="black"
      />
    </svg>
  )
}
