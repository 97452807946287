import { observer } from 'mobx-react-lite'
import { Flex, Box } from '@chakra-ui/react'
import { Link } from '@components/Link'
import { CaretDown } from '@components/Icon'
import { Store } from '@stores'

interface MenuItemProps {
  title: string
  hasDropdown?: boolean
  href?: string
  size?: 'md' | 'lg' | 'xl'
}

export const MenuItem = observer(
  ({ title, size = 'md', href = '', hasDropdown }: MenuItemProps) => {
    const { toggleDropdown, activeDropDown } = Store.ui
    const active = activeDropDown === title

    return (
      <Box mx={4} position="relative">
        {!hasDropdown && href !== '' && (
          <Link
            to={href}
            display="inline-block"
            textDecoration={active ? 'underline' : ''}
            fontSize={size}
          >
            <Box as="span">{title}</Box>
          </Link>
        )}

        {hasDropdown && href === '' && (
          <Flex
            as="button"
            alignItems="center"
            onClick={() => toggleDropdown(title)}
            cursor="pointer"
            textDecoration={active ? 'underline' : ''}
            _hover={{
              textDecoration: 'underline',
            }}
            _focus={{
              boxShadow: 'var(--chakra-shadows-outline)',
            }}
          >
            <Box as="span">{title}</Box>
            <Box as="span" paddingLeft={2}>
              <CaretDown />
            </Box>
          </Flex>
        )}
      </Box>
    )
  }
)
