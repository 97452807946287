import type { ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@chakra-ui/react'
import { Store } from '@stores'
import { Navigation } from '@components/Navigation'
import { Footer } from '@components/Footer'
import './layout.css'
import { StickyHeader } from '@components/StickyHeader'
import { Script } from 'gatsby'
import EmptyLayout from './EmptyLayout'

interface LayoutProps {
  children: ReactNode
  location?: {
    pathname?: string
  }
}

const DefaultLayout = observer(({ children }: LayoutProps) => {
  const { ui } = Store

  return (
    <Box overflow="hidden" pt={`${ui.stickyHeaderLayoutPadding}px`}>
      <StickyHeader>
        <Navigation />
      </StickyHeader>
      <Box as="main" flexGrow={1}>
        {children}
      </Box>
      <Script
        id="klaviyo"
        async
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WpAzez"
      />
      <Footer />
    </Box>
  )
})

const Layout = ({ children, location }: LayoutProps) => {
  if (location?.pathname?.includes('jointhelist')) {
    return (
      <EmptyLayout bg="linear-gradient(181.12deg, rgb(167, 169, 246) -2.97%, rgb(198, 234, 250) 92.1%)">
        {children}
      </EmptyLayout>
    )
  }

  return <DefaultLayout>{children}</DefaultLayout>
}

export default Layout
