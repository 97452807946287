export const Card = {
  parts: ['container', 'image', 'title', 'subtitle'],
  baseStyle: {
    container: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    image: {
      mb: 4,
      flexShrink: 0,
    },
    title: {
      fontWeight: 500,
      lineHeight: '1.1em',
      fontSize: 'lg',
      m: 0,
    },
    subtitle: {
      fontWeight: '400',
      lineHeight: '1.1em',
      my: 8,
    },
  },
  variants: {
    horizontal: {
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        w: 'auto !important',
      },
      image: {
        mb: 0,
        mr: 4,
      },
      title: {
        maxW: 48,
      },
    },
    vertical: {
      container: {
        flexDirection: 'column',
        w: 'auto',
      },
      image: {
        mb: 4,
      },
    },
    'vertical-center-align': {
      container: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      title: {
        textAlign: 'center',
      },
      image: {
        mb: 4,
      },
    },
  },
  sizes: {
    xs: {
      container: {
        w: 36,
      },
      image: {
        w: 36,
      },
      title: {
        fontSize: 18,
        lineHeight: '1.1em',
      },
      subtitle: {
        fontSize: 16,
        display: 'none',
      },
    },
    sm: {
      container: {
        w: 48,
      },
      image: {
        w: 48,
      },
      title: {
        fontSize: 18,
      },
      subtitle: {
        fontSize: 16,
      },
    },
    md: {
      container: {
        w: 64,
      },
      image: {
        w: 64,
      },
    },
    lg: {
      container: {
        w: 80,
      },
      image: {
        w: 80,
      },
    },
    full: {
      container: {
        w: 'full',
      },
      image: {
        w: 'full',
      },
    },
  },
}
