import { Container, Flex, useBreakpoint } from '@chakra-ui/react'
import type { ReactNode } from 'react'

interface SectionContainerProps {
  children: ReactNode
  bgColor?: string
  bgGradient?: 'purple' | 'yellow'
  noPadding?: boolean
  noPaddingLeft?: boolean
  noPaddingRight?: boolean
  size?: 'md' | 'lg'
  id?: string
}

const purpleGradient =
  'linear-gradient(180deg, #A7A9F6 25.52%, rgba(252, 213, 229, 0.54) 100%)'
const yellowGradient = 'linear-gradient(180deg, #FFF34B 0%, #F6D7E2 100%);'

export const SectionContainer = ({
  children,
  bgColor,
  bgGradient,
  noPadding,
  noPaddingLeft,
  noPaddingRight,
  id,
  size = 'md',
}: SectionContainerProps) => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  const isTablet = bp === 'md'

  let sidePadding = size === 'md' ? '3.8rem' : '6.6rem'

  if (isTablet) {
    sidePadding = size === 'md' ? '2.4rem' : '3.3rem'
  }

  if (isMobile) {
    sidePadding = size === 'md' ? '1rem' : '1.8rem'
  }

  return (
    <Flex
      py={noPadding ? 0 : 16}
      pr={noPaddingRight ? 0 : sidePadding}
      pl={noPaddingLeft ? 0 : sidePadding}
      background={bgColor}
      bgGradient={
        bgGradient === 'purple' ? purpleGradient : bgGradient && yellowGradient
      }
      direction="row"
      alignContent="center"
      justifyContent="center"
    >
      <Container id={id} maxW="container.xl" p={0} m={0} mx="auto">
        {children}
      </Container>
    </Flex>
  )
}
