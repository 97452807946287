export const Button = {
  baseStyle: {
    background: '#FCFBF8',
    borderRadius: '5.25rem',
    color: '#000',
    fontSize: 'md',
    fontWeight: 400,
    lineHeight: '1em',
  },
  sizes: {
    md: {
      px: '20',
    },
    stout: {
      px: '16',
      py: '1.06em',
    },
  },
  variants: {
    blue: {
      background: '#002FA4',
      color: '#FFFFFF',
      fontWeight: 500,
      _hover: {
        background: 'rgba(0, 47, 164, .75)',
      },
    },
  },
}
