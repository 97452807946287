import { ChakraProvider } from "@chakra-ui/react"
import Layout from "@components/Layout"
import Head from "@components/Head"
import theme from "./src/theme"
import { getFirebase } from "./src/firebase"

export function onClientEntry() {
  const queryParams = new URLSearchParams(window.location.search)
  const isDynamicLinkUrl = window.location.pathname === "/app/"
  const hasLinkParam = queryParams.has("link")
  if (hasLinkParam && !isDynamicLinkUrl) {
    const newUrl = `${window.location.origin}/app/${window.location.search}`
    window.location.replace(newUrl)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme}>
      <Head />
      {element}
    </ChakraProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export function onRouteUpdate({ location, prevLocation }) {
  if (prevLocation !== null) {
    const lazyApp = import("firebase/app")
    const lazyAnalytics = import("firebase/analytics")

    Promise.all([lazyApp, lazyAnalytics]).then(([firebase, analytics]) => {
      const app = getFirebase(firebase).getApp()
      const appAnalytics = analytics.getAnalytics(app)

      analytics.logEvent(appAnalytics, "page_view")
      analytics.setCurrentScreen(appAnalytics, location.pathname)
    })
  }
}
