import { UI } from './ui'

export class ReesesBookStore {
  ui

  constructor() {
    this.ui = new UI(this)
  }
}

export const Store = new ReesesBookStore()
