export const Close = () => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M14 2L2 14"
      stroke="#929290"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M2 2L14 14"
      stroke="#929290"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
)
