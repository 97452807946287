import { action, computed, makeObservable, observable } from 'mobx'
import { isBrowser } from 'browser-or-node'
import { ReesesBookStore } from '.'

export class UI {
  rootStore: ReesesBookStore
  isDropDownOpen = false
  activeDropDown = ''
  stickyHeader = false
  stickyHeaderOpen = false
  headerHeight = -1
  scrollDirection = 'down'
  lastY = 0

  constructor(rootStore: ReesesBookStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      isDropDownOpen: observable,
      activeDropDown: observable,
      toggleDropdown: action,
      toggleStickyHeader: action,
      toggleStickyHeaderOpen: action,
      headerHeight: observable,
      stickyHeader: observable,
      stickyHeaderOpen: observable,
      stickyThreshold: computed,
      stickyHeaderLayoutPadding: computed,
    })

    this.toggleStickyHeader = this.toggleStickyHeader.bind(this)
    this.toggleStickyHeaderOpen = this.toggleStickyHeaderOpen.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.setHeaderHeight = this.setHeaderHeight.bind(this)
    this.handleScroll = this.handleScroll.bind(this)

    if (isBrowser) {
      window.addEventListener('scroll', this.handleScroll)
    }
  }

  handleScroll() {
    if (this.headerHeight === -1) {
      return
    }

    const y = window.scrollY

    if (y > this.lastY) {
      // SCROLLING DOWN
      if (y > this.stickyThreshold) {
        if (!this.stickyHeader) {
          // PUT NAV AT TOP OF VIEWPORT
          this.toggleStickyHeader()
        }

        if (this.stickyHeaderOpen) {
          // HIDE IF SCROLLING DOWN PAGE
          this.toggleStickyHeaderOpen()
        }
      }
    } else {
      // SCROLLING UP
      if (y <= 0) {
        // RESET HEADER
        if (this.stickyHeader) {
          this.toggleStickyHeader()
        }

        if (this.stickyHeaderOpen) {
          this.toggleStickyHeaderOpen()
        }
      } else {
        // OPEN NAV ON SCROLL UP
        if (!this.stickyHeaderOpen) {
          this.toggleStickyHeaderOpen()
        }
      }
    }

    this.lastY = y
  }

  get stickyThreshold() {
    return this.headerHeight + this.headerHeight * 0.25
  }

  get stickyHeaderLayoutPadding() {
    return this.stickyHeader ? this.headerHeight : 0
  }

  setHeaderHeight(height: number) {
    this.headerHeight = height
  }

  toggleStickyHeaderOpen() {
    if (this.stickyHeaderOpen) {
      this.stickyHeaderOpen = false
    } else {
      this.stickyHeaderOpen = true
    }
  }

  toggleStickyHeader() {
    if (this.stickyHeader) {
      this.stickyHeader = false
    } else {
      this.stickyHeader = true
    }
  }

  toggleDropdown(menu?: string) {
    if (menu && menu !== this.activeDropDown) {
      this.activeDropDown = menu
      this.isDropDownOpen = true
    } else {
      this.isDropDownOpen = false
      this.activeDropDown = ''
    }
  }
}
