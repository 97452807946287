export const Play = (props: any) => {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill={props.fill ? props.fill : '#FCFBF8'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.5 13.134C23.1667 13.5189 23.1667 14.4811 22.5 14.866L1.5 26.9904C0.833333 27.3753 6.44345e-07 26.8942 6.77994e-07 26.1244L1.73794e-06 1.87564C1.77159e-06 1.10584 0.833336 0.624718 1.5 1.00962L22.5 13.134Z" />
    </svg>
  )
}
