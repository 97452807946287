export const Hero = {
  parts: [
    'container',
    'contentContainer',
    'textContainer',
    'largeTitle',
    'smallTitle',
    'ctaCopy',
    'image',
  ],
  baseStyle: {
    container: {
      maxWidth: 'container.lg',
      mx: 'auto',
      color: 'inherit',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      mx: 'auto',
    },
    textContainer: {
      maxWidth: 'xl',
      pr: 8,
      textAlign: 'left',
      flexDirection: 'column',
    },
    smallTitle: {
      fontSize: 18,
      lineHeight: '1em',
      fontWeight: 500,
      textTransform: 'uppercase',
      fontFamily: 'Larsseit, sans-serif',
      mb: 4,
    },
    largeTitle: {
      fontSize: 77,
      lineHeight: '1em',
      textTransform: 'uppercase',
    },
    image: {
      w: 96,
      flexShrink: 0,
    },
  },
  sizes: {
    md: {
      container: {
        py: 0,
      },
    },
    compact: {
      container: {
        py: 6,
      },
    },
    lg: {
      container: {
        py: 24,
      },
    },
  },
  variants: {
    normal: {
      contentContainer: {
        alignItems: 'center',
      },
    },
    compact: {
      contentContainer: {
        alignItems: 'center',
      },
      largeTitle: {
        fontSize: 62,
      },
      image: {
        w: 80,
      },
    },
    longText: {
      largeTitle: {
        fontSize: 48,
        lineHeight: '50px',
        marginBottom: '1em',
        marginTop: '.5em',
      },
    },
    vertical: {
      contentContainer: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      textContainer: {
        alignItems: 'center',
        textAlign: 'center',
        pr: 0,
      },
      largeTitle: {
        fontSize: 62,
        mb: 8,
      },
      ctaCopy: {
        mx: 'auto',
      },
      image: {
        mb: 8,
      },
    },
  },
}
