export const AppCta = {
  parts: [
    'container',
    'contentContainer',
    'appLinks',
    'largeText',
    'smallText',
  ],
  baseStyle: {
    container: {
      maxW: 'container.lg',
      mx: 'auto',
    },
    contentContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    smallText: {
      fontWeight: 500,
      textTransform: 'uppercase',
      lineHeight: '1em',
      textAlign: 'left',
      mb: 4,
    },
    largeText: {
      fontSize: 40,
      textTransform: 'uppercase',
      lineHeight: '1.1em',
      textAlign: 'left',
    },
  },
  variants: {
    horizontal: {
      contentContainer: {
        flexDirection: 'row',
      },
    },
    vertical: {
      contentContainer: {
        flexDirection: 'column',
      },
      smallText: {
        textAlign: 'center',
      },
      largeText: {
        textAlign: 'center',
      },
    },
  },
}
