import { FormEventHandler, useEffect, useState } from 'react'
import { Button, Flex, Input } from '@chakra-ui/react'
import { RightArrow } from '@components/Icon'

export const NewsletterForm = () => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (submitted) {
      fetch('https://a.klaviyo.com/client/subscriptions/?company_id=WpAzez', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          revision: '2023-10-15',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              custom_source: 'Website Footer',
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: email,
                  },
                },
              },
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: 'WBe66M',
                },
              },
            },
          },
        }),
      })
        .then(response => {
          if (response.ok) {
            setSuccess(true)
          }
        })
        .catch(err => {
          console.error(err)
          setSuccess(false)
        })
    }
  }, [setSuccess, submitted])

  const handleSubmit: FormEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    if (!email) return
    setSubmitted(true)
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      direction="row"
      mb={12}
      mt={4}
      maxW="sm"
    >
      {!success ? (
        <>
          <Input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter email address"
            bgColor="white"
            border="none"
            borderLeftRadius={90}
            lineHeight="1em"
            pl={8}
            py={6}
            disabled={success}
          />
          <Button
            type="submit"
            borderLeftRadius={0}
            bgColor="white"
            px={8}
            py={6}
            lineHeight="1em"
            disabled={success}
          >
            <RightArrow />
          </Button>
        </>
      ) : (
        <Flex> Thank you, check your inbox weekly.</Flex>
      )}
    </Flex>
  )
}
