import { extendTheme } from '@chakra-ui/react'
import {
  AppCta,
  StickyHeader,
  Footer,
  Hero,
  Heading,
  Button,
  Text,
  Card,
  Tag,
} from './components'

const theme = extendTheme({
  styles: {
    global: {
      'html, body, #___gatsby, #gatsby-focus-wrapper': {
        minHeight: 'full',
        fontFamily: 'Larsseit, sans-serif',
        fontWeight: '400',
      },
      '#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings': {
        bgColor: 'transparent !important',
        color: 'inherit !important',
        fontSize: '0.75rem !important',
        fontWeight: '500 !important',
        textAlign: 'center !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px !important',
      },
    },
  },
  colors: {
    grayLight: '#F3F3F3',
  },
  components: {
    AppCta,
    Button,
    Card,
    Footer,
    Heading,
    Hero,
    Text,
    StickyHeader,
    Tag,
  },
})

export default theme
