import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { Facebook, Instagram, Twitter, TikTok, Youtube } from '@components/Icon'
import { Link } from '@components/Link'
import { NewsletterForm } from '@components/NewsletterForm'
import { SectionContainer } from '@components/SectionContainer'
import type { ReactNode } from 'react'

const SocialLink = ({ children, to }: { children: ReactNode; to: string }) => (
  <Link
    to={to}
    bgColor="white"
    w={16}
    h={16}
    borderRadius="100%"
    display="inline-flex"
    alignItems="center"
    justifyContent="center"
    mr={4}
    _last={{
      marginRight: 0,
    }}
  >
    {children}
  </Link>
)

export const Footer = () => {
  const variant = useBreakpointValue({ base: 'vertical', md: 'horizontal' })
  const styles = useMultiStyleConfig('Footer', { variant })
  const year = new Date().getFullYear()

  return (
    <SectionContainer bgColor="#FFF34B">
      <Box __css={styles.container}>
        <Flex sx={styles.contentContainer}>
          <Box __css={styles.newsletterContainer}>
            <Heading
              fontWeight={500}
              fontSize="3xl"
              variant="sans-serif"
              lineHeight="1em"
              mb={1}
            >
              Join the Newsletter
            </Heading>
            <Text>Dive into the latest from Reese's Book Club</Text>
            <NewsletterForm />
            <Text fontSize="xs" fontWeight={500}>
              &copy; {year} Reese&lsquo;s Book Club LLC. All Rights Reserved.
            </Text>
          </Box>
          <Box
            w="full"
            sx={{
              '.ot-sdk-show-settings': {
                size: 'xs',
                variant: 'link',
                bgColor: 'transparent',
                color: 'inherit',
                fontSize: 'xs',
                fontWeight: '500',
                textAlign: 'center',
                boxShadow: 'none',
              },
            }}
          >
            <Flex sx={styles.socialLinksContainer}>
              <SocialLink to="https://www.instagram.com/reesesbookclub">
                <Instagram />
              </SocialLink>
              <SocialLink to="https://www.facebook.com/ReesesBookClub">
                <Facebook />
              </SocialLink>
              <SocialLink to="https://twitter.com/reesesbookclub/">
                <Twitter />
              </SocialLink>
              <SocialLink to="https://www.tiktok.com/@reesesbookclub">
                <TikTok />
              </SocialLink>
              <SocialLink to="https://www.youtube.com/@ReeseWitherspoon">
                <Youtube />
              </SocialLink>
            </Flex>
            <Text sx={styles.legalLinksContainer}>
              <Link to="/privacy_policy">Privacy Policy</Link>
              <Box as="span" px={2}>
                |
              </Box>
              <Link to="/terms_and_conditions">Terms & Conditions</Link>
              <Box as="span" px={2}>
                |
              </Box>
              <Button
                id="ot-sdk-btn"
                className="ot-sdk-show-settings"
                sx={styles['ot-sdk-show-settings']}
                size="xs"
                variant="link"
                bgColor="transparent"
                color="inherit"
                fontSize="xs"
                fontWeight="500"
                textAlign="center"
                boxShadow="none"
              >
                Cookie Settings
              </Button>
            </Text>
          </Box>
        </Flex>
      </Box>
    </SectionContainer>
  )
}
