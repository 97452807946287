import { useRef, useEffect, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, useStyleConfig } from '@chakra-ui/react'
import { isBrowser } from 'browser-or-node'
import { Store } from '@stores'

export const StickyHeader = observer(
  ({ children }: { children: ReactNode }) => {
    const { ui } = Store
    const header = useRef<HTMLDivElement>(null)

    let variant = 'inline'

    if (ui.stickyHeader && !ui.stickyHeaderOpen) {
      variant = 'ready'
    } else if (ui.stickyHeader && ui.stickyHeaderOpen) {
      variant = 'open'
    }

    const styles = useStyleConfig('StickyHeader', { variant })

    const handleScroll = () => {
      const headerHeight = header.current?.offsetHeight || 0

      if (headerHeight > 0 && ui.headerHeight !== headerHeight) {
        ui.setHeaderHeight(headerHeight)
      }
    }

    useEffect(() => {
      if (isBrowser) {
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)

        return () => {
          window.removeEventListener('scroll', handleScroll)
          window.removeEventListener('resize', handleScroll)
        }
      }
    })

    return (
      <Box
        as="header"
        top={ui.stickyHeader || ui.stickyHeaderOpen ? ui.headerHeight * -1 : 0}
        __css={styles}
        ref={header}
      >
        {children}
      </Box>
    )
  }
)
