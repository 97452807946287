export const Facebook = () => (
  <svg
    width="18"
    height="32"
    viewBox="0 0 18 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9233 17.8539L16.8064 12.1096H11.2821V8.38191C11.2821 6.81037 12.0537 5.27856 14.5281 5.27856H17.0398V0.388154C17.0398 0.388154 14.7603 0 12.5811 0C8.03119 0 5.0575 2.75106 5.0575 7.73153V12.1096H0V17.8539H5.0575V31.7404C6.0716 31.8991 7.111 31.9818 8.16981 31.9818C9.22862 31.9818 10.268 31.8991 11.2821 31.7404V17.8539H15.9233Z"
      fill="black"
    />
  </svg>
)
