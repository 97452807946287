import { Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import './layout.css'

interface EmptyLayoutProps {
  children: ReactNode
  bg?: string
}

const EmptyLayout = ({ children, bg }: EmptyLayoutProps) => {
  return (
    <Box bg={bg} minH="100vh">
      {children}
    </Box>
  )
}

export default EmptyLayout
