export const Footer = {
  parts: [
    'container',
    'contentContainer',
    'newsletterContainer',
    'socialLinksContainer',
    'legalLinksContainer',
    'ot-sdk-show-settings',
  ],
  baseStyle: {
    container: {
      maxW: 'container.lg',
      mx: 'auto',
    },
    contentContainer: {
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    newsletterContainer: {
      pr: 12,
      flexShrink: 0,
      maxW: '2xl',
      textAlign: 'left',
    },
    newsletterHeading: {
      fontWeight: 500,
      fontSize: '3xl',
      lineHeight: '1em',
      mb: 1,
    },
    socialLinksContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      mb: 12,
    },
    legalLinksContainer: {
      fontWeight: 500,
      fontSize: 'xs',
      textAlign: 'right',
    },
    ['ot-sdk-show-settings']: {
      size: 'xs',
      variant: 'link',
      bgColor: 'transparent',
      color: 'inherit',
      fontSize: 'xs',
      fontWeight: 500,
      textAlign: 'center',
      boxShadow: 'none',
    },
  },
  variants: {
    horizontal: {
      contentContainer: {
        flexDirection: 'row',
      },
    },
    vertical: {
      contentContainer: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      newsletterContainer: {
        textAlign: 'center',
        pr: 0,
        mb: 8,
      },
      socialLinksContainer: {
        justifyContent: 'center',
      },
      legalLinksContainer: {
        textAlign: 'center',
      },
    },
  },
}
