import { globalHistory } from '@reach/router'
import slugify from 'slugify'
import { ReactNode, useEffect } from 'react'
import { AspectRatio, Box, Flex, Image, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { Store } from '@stores'
import { Link } from '@components/Link'

interface DropDownMenusProps {
  children: ReactNode
}

export const DropDownMenus = ({ children }: DropDownMenusProps) => (
  <Box pos="absolute" bottom={0} left={0} bgColor="white" width="100%">
    {children}
  </Box>
)

interface DropDownProps {
  cardVariant: 'horizontal' | 'vertical' | 'vertical-center-align'
  cardSize: 'xs' | 'sm' | 'md' | 'lg'
  title: string
  primary: boolean
  data: any
}

function getLinkUrl(data: any) {
  const slug = slugify(data.title || data.bookTitle?.title || '', {
    lower: true,
    trim: true,
    strict: true,
  })

  let url

  switch (data.__typename) {
    case 'ContentfulArticle':
    case 'Article':
      url = `/article/${slug}`
      break
    case 'ContentfulBook':
    case 'Book':
      url = `/picks/#${slug}`
      break
    default:
      url = '/'
      break
  }

  if (
    (data.externalUrl?.name || data.externalUrl?.label) &&
    data.externalUrl?.url
  ) {
    url = data.externalUrl?.url?.url as string
  }

  return url
}

export const DropDown = observer(({ title, primary, data }: DropDownProps) => {
  const { toggleDropdown, isDropDownOpen, activeDropDown } = Store.ui

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        if (isDropDownOpen) {
          toggleDropdown()
        }
      }
    })
  }, [isDropDownOpen, toggleDropdown])

  return (
    <Box
      pos="absolute"
      bottom={0}
      left="50%"
      zIndex={primary ? 105 : 55}
      transition="transform 150ms ease-out"
      py={8}
      w="100vw"
      h="max"
      transform={`translate(-50%, ${
        isDropDownOpen && activeDropDown === title ? '100%' : 0
      })`}
      bgColor="white"
    >
      <Flex
        direction="row"
        justifyContent="space-evenly"
        flexWrap="wrap"
        alignItems="flex-start"
        maxW="container.lg"
        mx="auto"
      >
        {data?.map(d => (
          <Flex key={d.id} direction="row" maxW="xs" alignItems="center">
            <Flex as={Link} to={getLinkUrl(d)} position="relative">
              <AspectRatio
                as="span"
                ratio={1}
                flexShrink={0}
                w={36}
                h={36}
                mb={0}
              >
                <Image
                  srcSet={
                    d?.coverImageSquare?.fluid?.srcSet ||
                    d?.coverImage?.fluid?.srcSet ||
                    d?.coverPhotoSquare?.fluid?.srcSet ||
                    d?.coverPhoto?.fluid?.srcSet ||
                    ''
                  }
                />
              </AspectRatio>

              {d?.productTag?.length && (
                <Flex
                  mt="2"
                  position="absolute"
                  right="2"
                  bottom="2"
                  zIndex="10"
                  py={1}
                  px={3}
                  background="white"
                  textColor="#002FA4"
                  borderRadius="sm"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  {d?.productTag}
                </Flex>
              )}
            </Flex>
            <Text
              as={Link}
              to={getLinkUrl(d)}
              ml="4"
              fontWeight="medium"
              fontSize="lg"
              lineHeight="1"
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {d.title}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  )
})
