export const StickyHeader = {
  variants: {
    inline: {
      position: 'relative',
    },
    ready: {
      position: 'fixed',
      left: 0,
      transform: 'translateY(0)',
      w: 'full',
      zIndex: 1000,
      transition: 'transform 150ms ease-out',
    },
    open: {
      position: 'fixed',
      left: 0,
      transform: 'translateY(100%)',
      w: 'full',
      zIndex: 1000,
      transition: 'transform 150ms ease-out',
    },
  },
}
