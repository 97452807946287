export const Twitter = () => (
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0726 2.84287C30.2073 4.06666 29.1458 5.10988 27.8879 5.97254C27.9004 6.2039 27.9066 6.46539 27.9066 6.75703C27.9066 8.37739 27.6581 9.99972 27.1612 11.624C26.6643 13.2483 25.9053 14.803 24.8841 16.2882C23.863 17.7734 22.6466 19.0892 21.235 20.2355C19.8233 21.3818 18.1265 22.2958 16.1445 22.9775C14.1625 23.6592 12.0382 24 9.77187 24C6.2348 24 2.97751 23.0921 0 21.2762C0.528473 21.3321 1.03532 21.3601 1.52055 21.3601C4.47548 21.3601 7.11471 20.4946 9.43824 18.7638C8.06048 18.7398 6.8266 18.3365 5.73658 17.554C4.64657 16.7715 3.89645 15.7716 3.48622 14.5543C3.89171 14.6277 4.29191 14.6644 4.68682 14.6644C5.25528 14.6644 5.81463 14.5939 6.36488 14.453C4.89455 14.173 3.6748 13.4766 2.7056 12.364C1.73644 11.2514 1.25186 9.96719 1.25186 8.51145V8.43642C2.15435 8.91156 3.117 9.16429 4.13981 9.19463C3.2688 8.6425 2.57822 7.92251 2.06807 7.03466C1.55789 6.1468 1.3028 5.18599 1.3028 4.15221C1.3028 3.06192 1.59013 2.04666 2.16479 1.10642C3.76374 2.97213 5.7012 4.46327 7.97718 5.57983C10.2532 6.6964 12.6949 7.31651 15.3023 7.44016C15.1911 6.99947 15.1354 6.53959 15.1352 6.06052C15.1352 4.38779 15.7578 2.95952 17.0029 1.77571C18.248 0.591903 19.7502 0 21.5094 0C23.3512 0 24.9026 0.637687 26.1636 1.91306C27.6043 1.64172 28.9532 1.15093 30.2105 0.440688C29.7261 1.89013 28.7928 3.00827 27.4105 3.79512C28.6809 3.65156 29.9016 3.33415 31.0726 2.84287H31.0726Z"
      fill="black"
    />
  </svg>
)
