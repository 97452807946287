import { Flex, Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { DropDownMenus } from './DropDownMenus'

interface MenuProps {
  children: ReactNode
  bgColor?: string
  primary: boolean
  dropdowns?: ReactNode[]
}

const MenuItems = ({ children, bgColor, primary }: MenuProps) => {
  return (
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="center"
      h="full"
      bgColor={bgColor || 'white'}
      position="relative"
      zIndex={primary ? 105 : 95}
      py={4}
      px={2}
    >
      {children}
    </Flex>
  )
}

export const Menu = ({ children, bgColor, primary, dropdowns }: MenuProps) => {
  return (
    <Box bgColor={bgColor}>
      <DropDownMenus>{dropdowns}</DropDownMenus>
      <MenuItems bgColor={bgColor} primary={primary}>
        {children}
      </MenuItems>
    </Box>
  )
}
