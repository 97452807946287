export const Hamburger = () => (
  <svg viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5864:13691)">
      <path
        d="M0.692383 12H17.3078"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.692383 6H17.3078"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.692383 18H17.3078"
        stroke="black"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5864:13691">
        <rect width="18" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
