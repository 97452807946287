import { FirebaseApp } from 'firebase/app'

const config = {
  apiKey: process.env.GATSBY_APP_FIREBASE_API_KEY,
  projectId: process.env.GATSBY_APP_FIREBASE_PROJECT_ID,
  appId: process.env.GATSBY_APP_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_APP_FIREBASE_MEASUREMENT_ID,
}

let instance: FirebaseApp

export function getFirebase(firebase: any): any {
  if (typeof window !== 'undefined') {
    if (instance) return instance
    firebase.initializeApp(config)

    instance = firebase

    return instance
  }

  return null
}
