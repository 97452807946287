exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-contentful-article-contentful-id-tsx": () => import("./../../../src/pages/article/{ContentfulArticle.contentful_id}.tsx" /* webpackChunkName: "component---src-pages-article-contentful-article-contentful-id-tsx" */),
  "component---src-pages-article-contentful-article-title-tsx": () => import("./../../../src/pages/article/{ContentfulArticle.title}.tsx" /* webpackChunkName: "component---src-pages-article-contentful-article-title-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-giftguide-tsx": () => import("./../../../src/pages/giftguide.tsx" /* webpackChunkName: "component---src-pages-giftguide-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jointhelist-tsx": () => import("./../../../src/pages/jointhelist.tsx" /* webpackChunkName: "component---src-pages-jointhelist-tsx" */),
  "component---src-pages-landing-contentful-rbc-com-landing-title-tsx": () => import("./../../../src/pages/landing/{ContentfulRbcComLanding.title}.tsx" /* webpackChunkName: "component---src-pages-landing-contentful-rbc-com-landing-title-tsx" */),
  "component---src-pages-litup-terms-and-conditions-tsx": () => import("./../../../src/pages/litup_terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-litup-terms-and-conditions-tsx" */),
  "component---src-pages-litup-tsx": () => import("./../../../src/pages/litup.tsx" /* webpackChunkName: "component---src-pages-litup-tsx" */),
  "component---src-pages-maketimechallenge-tsx": () => import("./../../../src/pages/maketimechallenge.tsx" /* webpackChunkName: "component---src-pages-maketimechallenge-tsx" */),
  "component---src-pages-picks-tsx": () => import("./../../../src/pages/picks.tsx" /* webpackChunkName: "component---src-pages-picks-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */),
  "component---src-pages-readership-tsx": () => import("./../../../src/pages/readership.tsx" /* webpackChunkName: "component---src-pages-readership-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

