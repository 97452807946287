export const Tag = {
  baseStyle: {
    position: 'relative',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '1em',
    textAlign: 'center',
    clipPath: 'polygon(100% 0px, 96% 50%, 100% 100%, 0px 100%, 0px 0px)',
    px: 4,
    pr: 5,
    py: 2,
    _after: {
      content: '\'\'',
      position: 'absolute',
      right: 0,
      top: 0,
      width: 0,
      height: 0,
      borderTop: '.9em solid transparent',
      borderBottom: '.9em solid transparent',
      borderRight: '.9em solid transparent',
    },
  },
  variants: {
    yellow: {
      backgroundColor: 'rgb(255, 243, 75)',
    },
    blue: {
      backgroundColor: 'rgb(198, 234, 250)',
    },
  },
  sizes: {
    normal: {
      w: 'auto',
    },
    full: {
      w: 'full',
    },
  },
}
