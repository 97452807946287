export const Heading = {
  baseStyle: {
    fontFamily: 'Plantin Std Bold Condensed, serif',
    cursor: 'default',
  },
  variants: {
    'sans-serif': {
      fontFamily: 'Larsseit, sans-serif',
    },
  },
}
