import { useState } from 'react'
import { getFirebase } from '@fire'

export const useFirebase = () => {
  const [app, setApp] = useState<any>(null)
  const [appAnalytics, setAppAnalytics] = useState<any>(null)

  const lazyApp = import('firebase/app')
  const lazyAnalytics = import('firebase/analytics')
  let lazyLogEvent: any
  let lazySetCurrentScreen: any

  Promise.all([lazyApp, lazyAnalytics]).then(([firebase, analytics]) => {
    lazyLogEvent = analytics.logEvent
    lazySetCurrentScreen = analytics.setCurrentScreen
    let fbApp = getFirebase(firebase)
    if (!fbApp) return
    fbApp = fbApp.getApp()
    setApp(fbApp)
    setAppAnalytics(analytics.getAnalytics(fbApp))
  })

  const logEvent = (event: string) => {
    if (appAnalytics) {
      lazyLogEvent(appAnalytics, event)
    }
  }

  const setCurrentScreen = (screen: string) => {
    if (appAnalytics) {
      lazySetCurrentScreen(screen)
    }
  }

  return { logEvent, app, analytics: appAnalytics, setCurrentScreen }
}
