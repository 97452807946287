import { graphql, useStaticQuery } from 'gatsby'
import { Menu } from './Menu'
import { MenuItem } from './MenuItem'
import { DropDown } from './DropDownMenus'

export const MainMenu = () => {
  const data = useStaticQuery(graphql`
    query MainDropdownMenus {
      allContentfulRbcComDropdownMenu(
        filter: { secondaryMenuDropdown: { eq: false } }
      ) {
        nodes {
          id
          title
          slug
          items {
            __typename
            ... on Node {
              ... on ContentfulArticle {
                id
                title
                subtitle {
                  subtitle
                }
                productTag
                coverPhoto {
                  fluid {
                    srcSet
                    aspectRatio
                  }
                }
              }
              ... on ContentfulBook {
                id
                bookTitle: title {
                  title
                }
                productTag
                coverImage {
                  fluid {
                    srcSet
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const dropdowns = data?.allContentfulRbcComDropdownMenu?.nodes
  const slugs: { [key: string]: any } = {}
  dropdowns.forEach((node: any) => (slugs[node.slug] = node.items))

  const dropdownMenus = dropdowns.map(d => (
    <DropDown
      primary={true}
      cardVariant="horizontal"
      cardSize="xs"
      title={d.title || ''}
      data={d.items}
      key={d.id}
    />
  ))

  return (
    <Menu primary={true} dropdowns={dropdownMenus}>
      <MenuItem href="/picks" title="Our Picks" key="our-picks" />
      <MenuItem href="/who-we-are" title="Who We Are" key="who-we-are" />
      <MenuItem href="/readership" title="Readership" key="readership" />
      <MenuItem href="/litup" title="Lit Up" key="lit-up" />
      <MenuItem
        href="https://shop.reesesbookclub.com/"
        title="Shop"
        key="shop"
      />
      {slugs['giving-back'] ? (
        <MenuItem title="Giving Back" hasDropdown key="giving-back" />
      ) : null}
      <MenuItem href="/faq" title="FAQs" key="faqs" />
    </Menu>
  )
}
