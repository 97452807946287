/* eslint-disable @typescript-eslint/no-empty-function */
import { ChakraProps, Link as ChakraLink } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { forwardRef, ReactNode, useEffect } from 'react'
import { useFirebase } from '@hooks/useFirebase'

interface LinkProps extends ChakraProps {
  children: ReactNode
  to: string
  options?: {
    replace: boolean
  }
  linkEvent?: string
}

export const Link = forwardRef(
  ({ to, children, linkEvent, ...rest }: LinkProps, ref) => {
    const { logEvent } = useFirebase()

    const triggerEvent = () => {
      if (linkEvent && linkEvent !== '') {
        logEvent(linkEvent)
      }
    }

    useEffect(() => {}, [])
    try {
      const url = new URL(to)

      const isExternal =
        (to.includes('https://') || to.includes('http://')) &&
        (!to.includes('reesesbookclub') || !to.includes('reeses-book-club'))

      return (
        <ChakraLink
          onClick={triggerEvent}
          href={url.toString()}
          isExternal={isExternal}
          {...rest}
        >
          {children}
        </ChakraLink>
      )
    } catch {
      return (
        <ChakraLink as={GatsbyLink} to={to} onClick={triggerEvent} {...rest}>
          {children}
        </ChakraLink>
      )
    }
  }
)
